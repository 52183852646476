import React, { useState, useRef } from "react";

// Custom Components
import NavBar from "../../components/NavBar";
import HouseDisplay from "../../components/HouseDisplay";
import HouseInfo from "../../components/HouseInfo";
import Separator from "../../components/Separator";
import SlideShow from "../../components/SlideShow";

// Images
import tylerDurden from "../../assets/images/tyler_durden_transparent.png";
import womenAndFeminityTheme from "../../assets/images/women_and_feminity_theme.png";
import nightDriveTheme from "../../assets/images/night_drive_theme.png";
import natureTheme from "../../assets/images/nature_theme.png";
import greatMenTheme from "../../assets/images/great_men_theme.png";
import adventureTheme from "../../assets/images/adventure_theme.png";
import { typicalHousePhotos } from "../../constants/typicalHousePhotos";

// UI Components
import { Hidden } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// Screen size management
import Media from "react-media";

const styles = {
  housePageWrapper: {
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#323d4c",
    color: "#e7e7e7",
    minHeight: "100vh",
    width: "100vw",
    overflowY: "scroll",
    fontFamily: "Baskerville Old Face2",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  houseWrapperDesktop: {
    marginLeft: "10vw",
    marginRight: "10vw",
    marginTop: "30px"
  },
  houseWrapperMobile: {
    marginLeft: "40px",
    marginRight: "40px",
    marginTop: "30px"
  },
  slideShow: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    filter: "blur(0)"
  },
  closeIcon: {
    fill: "#e7e7e7",
    fontSize: "30px",
    opacity: "0.8",
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    zIndex: "20"
  },
  slideImageStyleDesktop: {
    height: "90vh",
    width: "auto"
    // height: "auto",
    // width: "min(90vw,700px)"
  },
  slideImageStyleMobile: {
    height: "auto",
    width: "90vw"
  },
  slideShowStyle: {
    width: "min(90vw,700px)"
  },
  title: {
    marginBottom: "20px",
    textAlign: "left",
    fontSize: "25px",
    fontWeight: "bold"
  },
  space: {
    marginTop: "35px"
  },
  verySmallText: {
    fontSize: "13px"
  },
  bottomlink: {
    color: "inherit"
  },
  tylerDurdenPhoto: {
    height: "250px",
    width: "auto",
    position: "absolute",
    right: "20px",
    bottom: "20px"
  }
};

const slideShowImages = [
  {
    src: adventureTheme,
    alt: "Women and Feminity"
  },
  {
    src: nightDriveTheme,
    alt: "Women and Feminity"
  },
  {
    src: greatMenTheme,
    alt: "Women and Feminity"
  },
  {
    src: womenAndFeminityTheme,
    alt: "Women and Feminity"
  },
  {
    src: natureTheme,
    alt: "Women and Feminity"
  }
];

const HousePage = props => {
  const [slideShowDisplayed, setSlideShowDisplayed] = useState(false);

  const displaySlideShow = () => {
    rootRef.current.style.filter = "blur(3px)";
    setSlideShowDisplayed(true);
  };

  const closeSlideShow = () => {
    rootRef.current.style.filter = "blur(0px)";
    setSlideShowDisplayed(false);
  };

  const rootRef = useRef(null);

  const [displayHousePhotos, setDisplayHousePhotos] = useState(true);
  const [displaySubliminal, setDisplaySubliminal] = useState(false);
  return (
    <div>
      {slideShowDisplayed && (
        <div>
          <Hidden smDown>
            <div style={styles.slideShow}>
              <CloseIcon style={styles.closeIcon} onClick={closeSlideShow} />
              <SlideShow
                slideImages={typicalHousePhotos}
                slideImageStyle={styles.slideImageStyleDesktop}
                slideShowStyle={styles.slideShowStyle}
                controls={true}
              />
            </div>
          </Hidden>
          <Hidden mdUp>
            <div style={styles.slideShow}>
              <CloseIcon style={styles.closeIcon} onClick={closeSlideShow} />
              <SlideShow
                slideImages={typicalHousePhotos}
                slideImageStyle={styles.slideImageStyleMobile}
                slideShowStyle={styles.slideShowStyle}
                controls={true}
              />
            </div>
          </Hidden>
        </div>
      )}
      <div ref={rootRef} style={styles.housePageWrapper}>
        <NavBar />
        <div style={styles.houseWrapperDesktop}>
          <div style={styles.title}>The typical house</div>
          <HouseDisplay
            setDisplaySubliminal={setDisplaySubliminal}
            displaySlideShow={displaySlideShow}
          />
          <Separator />
          <div style={styles.title}>Description</div>
          <HouseInfo />
          <div style={styles.space}></div>
          <div style={styles.verySmallText}>
            Got a question? Check our{" "}
            <a style={styles.bottomlink} href="/about">
              FAQ
            </a>{" "}
            or reach out to{" "}
            <a style={styles.bottomlink} href="mailto: max@fallrise.homes">
              max@fallrise.homes
            </a>
          </div>
        </div>
        {displaySubliminal && (
          <img
            alt="tyler durden"
            src={tylerDurden}
            style={styles.tylerDurdenPhoto}
          />
        )}
      </div>
    </div>
  );
};

// const HousePageMobile = props => {
//   return (
//     <div style={styles.housePageWrapper}>
//       <NavBar />
//       <div style={styles.houseWrapperMobile}>
//         <div style={styles.title}>The typical house</div>
//         <HouseDisplay setDisplaySubliminal={props.setDisplaySubliminal} />
//         <Separator />
//         <div style={styles.title}>Description</div>
//         <HouseInfo />
//         {/* <HouseInfo title="Description" informationItems={houseDescription} />
//         <HouseInfo title="Amenities" informationItems={houseDescription} /> */}
//       </div>
//       {props.displaySubliminal && (
//         <img
//           alt="tyler durden"
//           src={tylerDurden}
//           style={styles.tylerDurdenPhoto}
//         />
//       )}
//     </div>
//   );
// };

// const HousePage = () => {
//   const [displayHousePhotos, setDisplayHousePhotos] = useState(true);
//   const [displaySubliminal, setDisplaySubliminal] = useState(false);
//   return (
//     <Media
//       queries={{
//         small: "(max-width: 780px)",
//         large: "(min-width: 780px)"
//       }}
//     >
//       {matches => (
//         <React.Fragment
//           style={{
//             filter: true ? "blur(3px)" : ""
//           }}
//         >
//           {matches.small && (
//             <HousePageMobile
//               displaySubliminal={displaySubliminal}
//               setDisplaySubliminal={setDisplaySubliminal}
//             />
//           )}
//           {matches.large && (
//             <HousePageDesktop
//               displaySubliminal={displaySubliminal}
//               setDisplaySubliminal={setDisplaySubliminal}
//             />
//           )}
//         </React.Fragment>
//       )}
//     </Media>
//   );
// };

export default HousePage;
