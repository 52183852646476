// Images
import applyPng from "../assets/images/apply_gold.png";
import mansionPng from "../assets/images/mansion_gold.png";
import fistBumpPng from "..//assets/images/fist_bump_gold.png";
import weightliftingPng from "../assets/images/weightlifting_gold.png";
import gladiatorPng from "../assets/images/gladiator_gold.png";

export const explanationImages = [
  {
    src: applyPng,
    alt: "Application Icon",
    text: "1. Apply to\nFallrise Homes",
    style: {
      width: "90px"
    }
  },
  {
    src: mansionPng,
    alt: "Mansion Icon",
    text: "2. Book a room in\na house on Fallrise Homes",
    style: {
      width: "115px"
    }
  },
  {
    src: fistBumpPng,
    alt: "Fists Icon",
    text: "3. Live with 4-6 other\nkillers for 2 months",
    style: {
      width: "130px"
    }
  },
  {
    src: weightliftingPng,
    alt: "Weighlifting Icon",
    text: "4. Form brotherhoods,\nwork on your projects",
    style: {
      width: "60px"
    }
  },
  {
    src: gladiatorPng,
    alt: "Gladiator Icon",
    text: "5. Have fun conquering\nthe world",
    style: {
      width: "85px"
    }
  }
];
