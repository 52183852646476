// UI Components
import Button from "react-bootstrap/Button";

const styles = {
  button: {
    backgroundColor: "#dfbe8d",
    borderStyle: "none",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#505050",
    boxShadow: "0.5px 0.5px 5px 0 #1c222a"
  }
};

const GoldButton = props => {
  return (
    <Button
      href={props.linkTo}
      style={styles.button}
      type={props.buttonType}
      onClick={props.onClick}
    >
      {props.buttonText}
    </Button>
  );
};

export default GoldButton;
