import React from "react";

// UI Components
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

// Screen size management
import Media from "react-media";

// Images
import gmlLogo from "../../assets/images/fallrise_logo.png";

const styles = {
  navbarMobile: {
    fontSize: "14px",
    marginTop: "-20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#11141a"
  },
  navbarDesktop: {
    fontSize: "16px",
    marginTop: "-20px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "#11141a"
  },
  navbarTitleMobile: {
    fontSize: "10px"
  },
  navbarTitleDesktop: {
    fontSize: "25px"
  },
  logoImageMobile: {
    height: "30px"
  },
  logoImageDesktop: {
    height: "42px"
  },
  navLink: {
    color: "inherit",
    // opacity: "0.8",
    fontSize: "17px",
    paddingRight: "50px",
    paddingLeft: "50px"
  }
};

const NavBarMobile = () => {
  return (
    <Navbar
      style={styles.navbarMobile}
      collapseOnSelect
      expand="lg"
      variant="dark"
    >
      <Navbar.Brand style={styles.navbarTitleMobile} href="/">
        <img src={gmlLogo} alt="" style={styles.logoImageMobile} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav" style={styles.items}>
        <Nav className="mr-auto"></Nav>
        <Nav>
          <Nav.Link href="/about" style={styles.navLink}>
            FAQ
          </Nav.Link>
          <Nav.Link href="/first-house" style={styles.navLink}>
            Apply
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const NavBarDesktop = () => {
  return (
    <Navbar
      style={styles.navbarDesktop}
      collapseOnSelect
      expand="lg"
      variant="dark"
    >
      <Navbar.Brand style={styles.navbarTitleDesktop} href="/">
        <img src={gmlLogo} alt="" style={styles.logoImageDesktop} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav>
          <Nav.Link href="/about" style={styles.navLink}>
            FAQ
          </Nav.Link>
          <Nav.Link href="/first-house" style={styles.navLink}>
            Apply
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const NavBar = () => {
  return (
    <Media
      queries={{
        small: "(max-width: 450px)",
        large: "(min-width: 451px)"
      }}
    >
      {matches => (
        <React.Fragment>
          {matches.small && <NavBarMobile />}
          {matches.large && <NavBarDesktop />}
        </React.Fragment>
      )}
    </Media>
  );
};

export default NavBar;
