import React, { useEffect } from "react";

// Routing
import { BrowserRouter as Router, Route } from "react-router-dom";

// Pages
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import HousePage from "../pages/HousePage";
import RegisterPage from "../pages/RegisterPage";
// import ReactGA from "react-ga";

// ReactGA.initialize("UA-205237640-1");

const MainRouter = () => {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // });

  return (
    <Router>
      <Route path="/" exact component={HomePage} />
      <Route path="/about" exact component={AboutPage} />
      <Route path="/first-house" exact component={HousePage} />
      <Route path="/register" exact component={RegisterPage} />
    </Router>
  );
};

export default MainRouter;
