import React from "react";

//UI Components
import Carousel from "react-bootstrap/Carousel";

const styles = {
  imageContainer: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "flex-end",
    // alignItems: "flex-end"
    textAlign: "center"
  }
};

const SlideShow = props => {
  const slideItems = props.slideImages.map(image => (
    <Carousel.Item interval={3000}>
      <div>
        <img
          style={styles.image}
          src={image.src}
          alt={image.alt}
          style={props.slideImageStyle}
        />
      </div>
    </Carousel.Item>
  ));
  return (
    <Carousel
      fade
      controls={props.controls}
      indicators={false}
      style={props.slideShowStyle}
    >
      {slideItems}
    </Carousel>
  );
};

export default SlideShow;
