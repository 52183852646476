import React, { useState, useRef, useEffect } from "react";
// import ReactGA from "react-ga";

//UI Components
import { Grid, Hidden } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

//Custom Components
import SlideShow from "../../components/SlideShow";
import BlurSlideShow from "../../components/BlurSlideShow";
import ExplanationImages from "../../components/ExplanationImages";
import NavBar from "../../components/NavBar";
import GoldButton from "../../components/GoldButton";
import SilverButton from "../../components/SilverButton";
import Separator from "../../components/Separator";

// Images
import { explanationImages } from "../../constants/explanationImages";
import womenAndFeminityTheme from "../../assets/images/women_and_feminity_theme.png";
import nightDriveTheme from "../../assets/images/night_drive_theme.png";
import natureTheme from "../../assets/images/nature_theme.png";
import greatMenTheme from "../../assets/images/great_men_theme.png";
import adventureTheme from "../../assets/images/adventure_theme.png";

const styles = {
  root: {
    justifyContent: "right",
    textAlign: "center",
    backgroundColor: "#323d4c",
    color: "#e7e7e7",
    minHeight: "100vh",
    width: "100vw",
    overflowY: "scroll",
    fontFamily: "Baskerville Old Face2",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  homePageContainer: {
    marginRight: "7vw",
    marginLeft: "7vw"
  },
  bigTextLeft: {
    fontSize: "34px",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "pre-wrap"
  },
  buttonsContainer: {
    // fontSize: "34px",
    // fontWeight: "bold",
    // textAlign: "left",
    // whiteSpace: "pre-wrap"
    display: "flex",
    flexDirection: "row"
  },
  button: {
    marginRight: "20px"
  },
  bigTextCenter: {
    fontSize: "34px",
    fontWeight: "bold",
    textAlign: "center",
    whiteSpace: "pre-wrap"
  },
  mediumText: {
    fontSize: "18px",
    textAlign: "left",
    opacity: "0.96",
    whiteSpace: "pre-wrap"
  },
  smallText: {
    fontSize: "14px",
    opacity: "1",
    whiteSpace: "pre-wrap"
  },
  verySmallText: {
    fontSize: "13px"
  },
  viewImagesText: {
    textDecoration: "underline",
    fontWeight: "bold",
    cursor: "pointer"
  },
  space: {
    marginTop: "35px"
  },
  emailLink: {
    color: "inherit"
  },
  slideShowMobile: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    filter: "blur(0)"
  },
  closeIcon: {
    fill: "#e7e7e7",
    fontSize: "30px",
    opacity: "0.8",
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    zIndex: "20"
  },
  slideShowContainer: {
    display: "flex",
    justifyContent: "center"
  },
  slideImageStyle: {
    height: "400px",
    width: "auto"
  },
  slideImageStyleMobile: {
    height: "auto",
    width: "95vw"
  },
  slideShowStyle: {
    width: "min(100%, 500px)"
  },
  slideShowStyleMobile: {
    width: "95vw"
  }
};

const slideShowImages = [
  // {
  //   src: adventureTheme,
  //   alt: "Women and Feminity"
  // },
  {
    src: greatMenTheme,
    alt: "Women and Feminity"
  }
  // {
  //   src: nightDriveTheme,
  //   alt: "Women and Feminity"
  // },
  // // {
  // //   src: greatMenTheme,
  // //   alt: "Women and Feminity"
  // // },
  // {
  //   src: womenAndFeminityTheme,
  //   alt: "Women and Feminity"
  // },
  // {
  //   src: natureTheme,
  //   alt: "Women and Feminity"
  // }
];

const HomePage = () => {
  const rootRef = useRef(null);

  const [slideShowDisplayed, setSlideShowDisplayed] = useState(false);

  const displaySlideShow = () => {
    rootRef.current.style.filter = "blur(3px)";
    setSlideShowDisplayed(true);
  };

  const closeSlideShow = () => {
    rootRef.current.style.filter = "blur(0px)";
    setSlideShowDisplayed(false);
  };

  // useEffect(() => {
  //   ReactGA.initialize("UA-205237640-1");
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  //   ReactGA.pageview("/");
  //   console.log("react pageview");
  // }, []);

  return (
    <div>
      {slideShowDisplayed && (
        <div style={styles.slideShowMobile}>
          <CloseIcon style={styles.closeIcon} onClick={closeSlideShow} />
          <SlideShow
            slideImages={slideShowImages}
            slideImageStyle={styles.slideImageStyleMobile}
            slideShowStyle={styles.slideShowStyleMobile}
            controls={true}
          />
        </div>
      )}

      <div ref={rootRef} style={styles.root}>
        <NavBar />
        <div style={styles.homePageContainer}>
          <style jsx global>{`
            body {
              margin: 0px;
              padding: 0px;
            }
            * {
              margin-top: 0;
            }
          `}</style>

          <div style={styles.space}></div>

          <Grid
            container
            spacing={0}
            alignItems="center"
            // alignContent="flex-end"
          >
            <Grid item xs={12} sm={12} md={6} lg={7}>
              <div style={styles.bigTextLeft}>
                Live in a house with like-minded guys in a foreign country.
                Build yourself a crew.
              </div>
              <div style={styles.space}></div>
              <div style={styles.mediumText}>
                Nothing better than working toward huge goals, enjoying the
                highs and lows with your crew.{"\n"}
                {/* {"\n"}Nothing better than having a band of brothers with the
                same fundamental values.{"\n"} */}
                {/* <Hidden smDown>
                  <div>
                    {"\n"}If you connect with the themes and images on the
                    right, then it's for you.
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <div>
                    {"\n"}If you connect with{" "}
                    <span
                      style={styles.viewImagesText}
                      onClick={displaySlideShow}
                    >
                      those images
                    </span>
                    , then it's for you.
                  </div>
                </Hidden> */}
              </div>

              <div style={styles.space}></div>
              <div style={styles.buttonsContainer}>
                <div style={styles.button}>
                  <GoldButton linkTo="/first-house" buttonText="Apply" />
                </div>
                <div style={styles.button}>
                  <SilverButton linkTo="/about" buttonText="The Mission" />
                </div>
              </div>
            </Grid>
            <Hidden smDown>
              <Grid item sm={7} md={6} lg={5}>
                <div style={styles.slideShowContainer}>
                  <SlideShow
                    slideImages={slideShowImages}
                    slideImageStyle={styles.slideImageStyle}
                    slideShowStyle={styles.slideShowStyle}
                    controls={false}
                  />
                </div>
              </Grid>
            </Hidden>
          </Grid>

          <Separator />

          <div style={styles.space}></div>
          <div style={styles.bigTextCenter}>How it works:</div>

          <ExplanationImages explanationImages={explanationImages} />

          <div style={styles.space}></div>
          <div style={styles.verySmallText}>
            Reach out at{" "}
            <a style={styles.emailLink} href="mailto: max@fallrise.homes">
              max@fallrise.homes
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
