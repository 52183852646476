import React, { useEffect, useState, useRef } from "react";

import { useHistory } from "react-router-dom";

// Images
import housePhoto1 from "../../assets/images/typical_house_photo_1.jpg";

// Custom Components
import GoldButton from "../GoldButton";

// UI Components
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// Icons
import OpenWithIcon from "@material-ui/icons/OpenWith";

const styles = {
  housePhotoDesktop: {
    height: "300px",
    borderRadius: "10px",
    boxShadow: "0.5px 0.5px 3px 0 #1c222a"
  },
  housePhoto: {
    width: "100%",
    height: "auto",
    marginBottom: "5px",
    borderRadius: "10px",
    boxShadow: "0.5px 0.5px 3px 0 #1c222a"
  },
  slideImageStyle: {
    height: "400px",
    width: "auto"
  },
  slideShowStyle: {
    width: "min(80%, 700px)"
  },
  imageContainer: {
    position: "relative"
  },
  imageInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#323d4c",
    color: "#e7e7e7",
    position: "absolute",
    bottom: "10px",
    right: "10px",
    padding: "5px",
    border: "1px solid #1f2630",
    borderRadius: "9px",
    cursor: "pointer"
  },
  imageInfoText: {
    fontSize: "12px",
    marginLeft: "3px",
    marginRight: "3px"
  },
  imageInfoIcon: {
    height: "15px"
  },
  slideShow: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    filter: "blur(0)"
  },
  closeIcon: {
    fill: "#e7e7e7",
    fontSize: "30px",
    opacity: "0.8",
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    zIndex: "20"
  },
  slideImageStyleMobile: {
    height: "auto",
    width: "95vw"
  },
  slideShowStyleMobile: {
    width: "95vw"
  },
  textField: {
    fontSize: "18px",
    marginBottom: "15px"
  },
  buttonDiv: {
    marginTop: "30px"
  }
};

const HouseDisplay = props => {
  const timeout = delay => {
    return new Promise(res => setTimeout(res, delay));
  };

  useEffect(async () => {
    props.setDisplaySubliminal(true);
    await timeout(20);
    props.setDisplaySubliminal(false);
  }, []);

  const history = useHistory();

  const handleClick = async () => {
    props.setDisplaySubliminal(true);
    await timeout(250);
    props.setDisplaySubliminal(false);
    history.push("/register");
  };

  return (
    <div>
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <div style={styles.imageContainer}>
              <img
                alt="The typical house"
                src={housePhoto1}
                style={styles.housePhoto}
              />
              <div style={styles.imageInfo} onClick={props.displaySlideShow}>
                <OpenWithIcon style={styles.imageInfoIcon} />
                <div style={styles.imageInfoText}>Show all photos</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} U>
            <div style={styles.textBox}>
              <div style={styles.textField}>
                We are currently sourcing the first houses.
              </div>
              <div style={styles.textField}>
                The houses will look like the one showed here.
              </div>
              <div style={styles.textField}>
                Apply to be part of the first batch.
              </div>
              <div style={styles.textField}>
                No money deposit is required to apply.
              </div>
              <div style={styles.buttonDiv}>
                <GoldButton buttonText="Apply" onClick={handleClick} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HouseDisplay;
