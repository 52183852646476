import React, { useState } from "react";

// Custom Components
import NavBar from "../../components/NavBar";
import { Widget } from "@typeform/embed-react";
import { InlineWidget } from "react-calendly";

// Screen size management
import Media from "react-media";

const styles = {
  registerPageWrapper: {
    backgroundColor: "#323d4c",
    color: "#e7e7e7",
    minHeight: "100vh",
    width: "100vw",
    overflowY: "scroll",
    fontFamily: "Baskerville Old Face2",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  registerFormWrapper: {
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  applicationForm: {
    width: "80vw",
    height: "80vh",
    boxShadow: "0.5px 0.5px 10px 0 #1c222a"
  },
  calendarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  calendarTextMobile: {
    fontSize: "16px"
  },
  calendarTextDesktop: {
    fontSize: "20px"
  }
};

const RegisterPage = props => {
  const formId = "oIUkMo55";

  const url = "https://calendly.com/goodmanlivin/gml-intro";
  // const prefill = {
  //   email: formik.values.email,
  //   firstName: formik.values.firstName,
  //   lastName: formik.values.lastName,
  //   name: formik.values.firstName + " " + formik.values.lastName
  // };

  const [applicationStep, setApplicationStep] = useState(1);

  return (
    <div style={styles.registerPageWrapper}>
      <NavBar />
      <div style={styles.registerFormWrapper}>
        {applicationStep === 1 ? (
          <Widget
            id={formId}
            style={styles.applicationForm}
            onSubmit={() => {
              setApplicationStep(1.1);
            }}
          />
        ) : (
          <Media
            queries={{
              small: "(max-width: 400px)",
              mediumSmall: "(min-width: 401px) and (max-width: 600px)",
              mediumLarge: "(min-width: 601px) and (max-width: 849px)",
              large: "(min-width: 850px)"
            }}
          >
            {matches => (
              <React.Fragment>
                {matches.small && (
                  <div style={styles.calendarWrapper}>
                    <div style={styles.calendarTextMobile}>
                      Let's check vibes. Book a call with us:
                    </div>
                    <InlineWidget
                      styles={{
                        marginTop: "30px",
                        width: "280px",
                        height: "500px"
                      }}
                      url={url}
                    />
                  </div>
                )}
                {matches.mediumSmall && (
                  <div style={styles.calendarWrapper}>
                    <div style={styles.calendarTextMobile}>
                      Let's check vibes. Book a call with us:
                    </div>
                    <InlineWidget
                      styles={{
                        marginTop: "30px",
                        width: "350px",
                        height: "500px"
                      }}
                      url={url}
                    />
                  </div>
                )}
                {matches.mediumLarge && (
                  <div style={styles.calendarWrapper}>
                    <div style={styles.calendarTextDesktop}>
                      Let's check vibes. Book a call with us:
                    </div>
                    <InlineWidget
                      styles={{
                        marginTop: "30px",
                        width: "500px",
                        height: "500px"
                      }}
                      url={url}
                    />
                  </div>
                )}
                {matches.large && (
                  <div style={styles.calendarWrapper}>
                    <div style={styles.calendarTextDesktop}>
                      Let's check vibes. Book a call with us:
                    </div>
                    <InlineWidget
                      styles={{
                        marginTop: "-40px",
                        width: "1000px",
                        height: "700px"
                      }}
                      url={url}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </Media>
        )}
      </div>
    </div>
  );
};

export default RegisterPage;
