import React from "react";

const styles = {
  hr: {
    marginTop: "40px",
    width: "50%",
    border: "2px solid #1f2630",
    borderRadius: "25px"
  }
};

const Separator = () => {
  return <hr style={styles.hr}></hr>;
};

export default Separator;
