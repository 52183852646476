import React, { useState, useEffect } from "react";

// UI Components
import Card from "react-bootstrap/Card";

// Icons
import { PlusLg } from "react-bootstrap-icons";
import { DashLg } from "react-bootstrap-icons";

const QuestionBox = props => {
  const styles = {
    questionBoxWrapper: {
      marginTop: "15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
      // height: "50px"
      // transition: "2s"
    },
    questionBoxCardContainer: {
      width: "70vw"
    },
    questionBoxCard: {
      // height: "auto",
      // transition: "height 2s linear",
      paddingLeft: "40px",
      paddingRight: "15px",
      paddingTop: "15px",
      paddingBottom: "15px",
      backgroundColor: "#424f5f",
      borderRadius: "12px",
      boxShadow: "0.5px 0.5px 10px 0 #1c222a"
    },
    questionBoxItems: {
      display: "flex",
      flexDirection: "row"
    },
    questionBoxIcon: {
      flexGrow: "1",
      textAlign: "left",
      paddingRight: "15px"
    },
    questionBoxQuestion: {
      flexGrow: "19",
      fontSize: "20px",
      textAlign: "left",
      display: "flex",
      flexDirection: "column"
    },
    answer: {
      marginTop: "5px",
      fontSize: "16px",
      // opacity: "0.85",
      whiteSpace: "pre-wrap"
    }
  };

  const [answerDisplay, setAnswerDisplay] = useState(false);

  useEffect(() => {
    if (props.question === "The Mission" && !answerDisplay) {
      setAnswerDisplay(true);
    }
  }, []);

  const handleAnswerDisplay = () => {
    if (answerDisplay === false) {
      setAnswerDisplay(true);
    } else if (answerDisplay === true) {
      setAnswerDisplay(false);
    }
  };

  return (
    <div style={styles.questionBoxWrapper}>
      <div style={styles.questionBoxCardContainer}>
        <div style={styles.questionBoxCard}>
          <div style={styles.questionBoxItems}>
            <div style={styles.questionBoxIcon} onClick={handleAnswerDisplay}>
              {answerDisplay ? <DashLg /> : <PlusLg />}
            </div>
            <div style={styles.questionBoxQuestion}>
              <div>{props.question}</div>
              <div>
                {answerDisplay && (
                  <div style={styles.answer}>{props.answer}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionBox;
