import React from "react";

// UI Components
import { Grid } from "@material-ui/core";

const styles = {
  houseInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  infoList: {
    textAlign: "left"
  },
  listItem: {
    paddingBottom: "10px",
    fontSize: "18px"
  },
  space: {
    marginTop: "40px"
  },
  row: {
    fontSize: "14px"
    // opacity: "0.8"
  },
  emailLink: {
    color: "inherit"
  }
};

const HouseInfo = () => {
  return (
    <div style={styles.houseInfoWrapper}>
      <div style={styles.infoList}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <li style={styles.listItem}>Duration: 2 months</li>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <li style={styles.listItem}>
              Locations: South-East Asia (Thailand, Bali, etc)
            </li>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <li style={styles.listItem}>Rooms: 5-6 individual bedrooms</li>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <li style={styles.listItem}>
              Rent: around $850 per month per person (bills included)
            </li>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HouseInfo;
