import housePhoto1 from "../assets/images/typical_house_photo_1.jpg";
import housePhoto2 from "../assets/images/typical_house_photo_2.jpg";
import housePhoto3 from "../assets/images/typical_house_photo_3.jpg";
import housePhoto4 from "../assets/images/typical_house_photo_4.jpg";
import housePhoto5 from "../assets/images/typical_house_photo_5.jpg";
import housePhoto6 from "../assets/images/typical_house_photo_6.jpg";
import housePhoto7 from "../assets/images/typical_house_photo_7.jpg";
import housePhoto8 from "../assets/images/typical_house_photo_8.jpg";

export const typicalHousePhotos = [
  {
    src: housePhoto1,
    alt: "House photo #1"
  },
  {
    src: housePhoto2,
    alt: "House photo #2"
  },
  {
    src: housePhoto3,
    alt: "House photo #3"
  },
  {
    src: housePhoto4,
    alt: "House photo #4"
  },
  {
    src: housePhoto5,
    alt: "House photo #5"
  },
  {
    src: housePhoto6,
    alt: "House photo #6"
  },
  {
    src: housePhoto7,
    alt: "House photo #7"
  },
  {
    src: housePhoto8,
    alt: "House photo #8"
  }
];
