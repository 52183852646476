// UI Components
import { Grid } from "@material-ui/core";

// Custom Components
import ExplanationImage from "./ExplanationImage";

// Constants
import { explanationImages } from "../../constants/explanationImages";

const styles = {
  explanationImagesWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-end",
    justifyContent: "center",
    marginTop: "20px",
    marginLeft: "30px",
    marginRight: "30px",
    marginBottom: "-20px"
  }
};

const ExplanationImages = props => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
      spacing={1}
    >
      {props.explanationImages.map(explanationImage => (
        <ExplanationImage explanationImage={explanationImage} />
      ))}
      {/* <Grid item xs={12} sm={6} md={4} lg={2}> */}
      {/* </Grid> */}
    </Grid>
  );
};

export default ExplanationImages;
