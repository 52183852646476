// Custom Components
import NavBar from "../../components/NavBar";
import QuestionBox from "../../components/QuestionBox";

// Images
import { aboutQuestions } from "../../constants/aboutQuestions";

const styles = {
  missionPageWrapper: {
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#323d4c",
    color: "#e7e7e7",
    minHeight: "100vh",
    width: "100vw",
    overflowY: "scroll",
    fontFamily: "Baskerville Old Face2",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  questionBoxesWrapper: {
    marginTop: "40px"
  }
};

const MissionPage = () => {
  return (
    <div style={styles.missionPageWrapper}>
      <NavBar />
      <div style={styles.questionBoxesWrapper}>
        {aboutQuestions.map(questionItem => {
          return (
            <QuestionBox
              question={questionItem.question}
              answer={questionItem.answer}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MissionPage;
