// UI Components
import { Grid } from "@material-ui/core";

const styles = {
  explanationImageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "35px",
    marginRight: "35px",
    marginBottom: "20px"
  },
  explanationImageText: {
    marginTop: "10px",
    whiteSpace: "pre-wrap",
    fontSize: "18px"
  }
};

const ExplanationImage = props => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <div style={styles.explanationImageWrapper}>
        <img
          alt={props.explanationImage.alt}
          src={props.explanationImage.src}
          style={props.explanationImage.style}
        />
        <div style={styles.explanationImageText}>
          {props.explanationImage.text}
        </div>
      </div>
    </Grid>
  );
};

export default ExplanationImage;
