export const aboutQuestions = [
  {
    question: "The Mission",
    answer:
      "Men on a mission, when they start their journey, find that their old friends from high school and college do not want what they want out of life.\n\nThey want to surround themselves with guys marching in the same direction as they are. A few friends you can go with on a warpath, be it in business, life, or with women.\n\nWe created Fallrise Homes so that guys can form brotherhoods, work on their projects, go through shit alone and together, and enjoy the ride (the highs and the lows).\n\nIf, like us, that’s what you want, apply for a 2-month stay in a house."
  },
  {
    question: "Who will be the other guys in the house ?",
    answer:
      'Killers that blasted through the application process.\n\nThe goal application process is to select guys that build and create shit, that take on their goals with intensity and speed, that burn boats when they have to, and roll the dice in different aspects of life, be it business or women.\n\nNo "fake cover-letter" type BS will be asked.\n\nGuys that will live in the same house will meet in person when moving in.'
  },
  {
    question: "How long are the stays ?",
    answer: "2 months. Everyone arrives on the same day."
  },
  {
    question: "Where will the houses be located ?",
    answer:
      "The first houses will be in Thailand, Bali, and the Phillipines.\n\nNearby the beach. Nearby or in the city. And close to a gym."
  },
  {
    question: "My application was accepted. What's next ?",
    answer:
      "Once your application is accepted, 2 things will happen:\n\n • You will have access to the page of this site where you can view the house, book your room, and view the terms and conditions of the booking agreed on with the owners.\n\n • You will need to apply for a tourist visa in the country where the house is located. Tourist visa are pretty straightforward to get and those from the countries where the first houses will be last between 60 and 90 days (i.e. longer than the length of the stay)"
  },
  {
    question: "How much is the rent ?",
    answer:
      "For the first houses, the monthly rent will be around $850 per month per person (bills included)."
  },
  {
    question: "When is move in going to be ?",
    answer:
      "Applications for the first house are open now; we are looking to move in November 2021 (all the logistics information will be provided by then)."
  },
  {
    question: "Underrated movie recommendation",
    answer: 'Try "The Lost City of Z".'
  }
  // {
  //   question: "I want to join Fallrise Homes",
  //   answer: "Send us an email at max@fallrise.homes."
  // }
];
